import React, { createContext, useCallback, useEffect, useState } from "react";

export const ComparisionContext = createContext();

const isWindow = typeof window === 'object' && typeof sessionStorage === 'object';

export const ComparisionProvider = ({ children }) => {
  const [comparisionItems, setComparisionItems] = useState(isWindow 
    ? JSON.parse(sessionStorage.getItem('product_comparision')) || {} 
    : {}
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [comparisionTabs, setComparisionTabs] = useState([]);

  const searchComparisionItemIndex = useCallback((key, id) => {
    return comparisionItems[key]?.indexOf(id) ?? -1;
  }, [comparisionItems]);

  const hasComparisionItem = useCallback((key, id) => {
    return searchComparisionItemIndex(key, id) !== -1;
  }, [searchComparisionItemIndex]);

  const removeAllComparisionItems = useCallback(() => {
    setComparisionItems({});
  }, []);

  const toggleComparisionItem = useCallback((key, id) => {
    const index = searchComparisionItemIndex(key, id);
    const currentItems = comparisionItems[key] || [];

    if (index === -1) {
      setComparisionItems({
        ...comparisionItems,
        [key] : [...currentItems, id],
      });
    } else {
      setComparisionItems({
        ...comparisionItems,
        [key] : currentItems.filter(itemId => itemId !== id),
      });
    }
  }, [comparisionItems, searchComparisionItemIndex]);

  const removeComparisionTabItem = useCallback((key, id) => {
    if (comparisionItems[key] && comparisionItems[key].includes(id)) {
      setComparisionItems({ 
        ...comparisionItems, 
        [key]: [...comparisionItems[key]].filter((pid) => {
          return pid !== id
        }),
      });
    }
    
    const currentTabs = comparisionTabs.reduce((prev, curr) => {
      if (curr.key === key) {
        curr.items = curr.items.filter((item) => Number(item.id) !== id);

        if (!curr.items.length) {
          return prev;
        }
      }

      return [...prev, curr];
    }, []);

    setComparisionTabs(currentTabs);
  }, [comparisionTabs, comparisionItems]);

  const fetchComparisionTabs = useCallback(() => {
    const entries = Object.entries(comparisionItems);

    if (!entries.length) {
      return;
    }

    const query = entries.reduce((prev, [key, values]) => {
      const mappedValues = values.map((el) => `${key}[]=${el}`);
   
      return [...prev, mappedValues.join('&')];
    }, []).join('&');

    fetch(`https://cms.akredo.pl/api/json/compare?${query}`)
      .then(res => res.json())
      .then(result => {
        if (result?.tabs) {
          setComparisionTabs(result.tabs);
          setIsLoaded(true);
        }
      });
  }, [comparisionItems]);

  useEffect(() => {
    sessionStorage.setItem('product_comparision', JSON.stringify(comparisionItems));
  }, [comparisionItems])
  
  return (
    <ComparisionContext.Provider value={{ 
      comparisionItems,
      comparisionTabs,
      isLoaded,
      toggleComparisionItem, 
      hasComparisionItem,
      searchComparisionItemIndex,
      removeAllComparisionItems,
      removeComparisionTabItem,
      fetchComparisionTabs, 
    }}>
      {children}
    </ComparisionContext.Provider>
  );
}